<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div
          class="title"
        >広島エリアで人気のチケットです。広島の魅力的な観光スポットと人気施設が対象し、有効期間で対象施設の中からお好きな３つ施設を選んで楽しめます。また、電子チケットQRコードを提示すると簡単に入場ができます。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●各施設のご利用は1回のみです。同施設を2回ご利用できません。
          <br />●パスの使用期間は購入日から
          <span>90日</span>以内です。
          <br />EX：
          <span>7/1</span>ご購入の場合、
          <span>7/1~9/28</span>使用可能です。
          <br />●パスの有効期間：1つ目の施設をご入場から
          <span>7日間</span>以内です。
          <br />EX：
          <span>7/1</span>から利用開始の場合、有効期間は
          <span>7/7</span>までです。
          <br />●利用可能施設数：参画施設の中から、任意の<span>3施設</span>
          <br />●注意：同オーダーに複枚数をご購入の場合、中の一枚を利用すると、全て同じ
          <span>7日間</span>有効になります。
          <br />●パスの有効期間7日間は購入日から
          <span>90日間</span>以内に限ります。
          <br />EX：
          <span>7/1~9/28</span>使用可能のパスを
          <span>9/27</span>から利用開始された場合、
          有効期間は
          <span>9/27~9/28</span>です。
          <br />●未使用分を払い戻すことはできません。
          <br />●各施設の営業時間・定休日・利用に関する注意事項は下記リンク、
          または施設公式サイトでご確認ください。
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt />
            </div>
          </el-image>
        </div>

        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr />
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='引き換え場所' && !contentItem.noColor">
                      {{cItem}}
                      <br />
                    </span>
                    <a
                      :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]"
                      target="_blank"
                      :href="cItem"
                      v-show="msg.title==='公式サイト' && !contentItem.noColor"
                    >
                      {{cItem}}
                      <br />
                    </a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      headImg: require("@/assets/images/hfhiroshima/jp/havefun_title_jp.jpg"),
      loadImg: require("@/assets/images/hfkansai/loading.png"),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfhiroshima/jp/ferry_JP.png'),
          title: ['広島港-呉港を結ぶクルーズフェリー片道券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '1. 広島港宇品旅客ターミナル1F　窓口','住所：広島市南区宇品海岸一丁目13-26','2. 呉港(中央桟橋)ターミナル1F　窓口','住所：呉市宝町4-44'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/kouro/cruise/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['利用可能路線: ','<br>・広島港→呉　港<br>・呉　港→広島港','※いずれかの区間　片道１回分の乗船となります。'] },
                { cid: 2, text: ['窓口でQRコードを認証せずに直接船に来られた場合は乗れませんのでご注意ください。'] },
                { cid: 3, text: ['広島-呉間の高速船（スーパージェット）はご利用できません。'] },
                { cid: 4, text: ['時刻表はHPをご覧ください。'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfhiroshima/jp/ferry2_JP.jpg'),
          title: ['広島港-宮島港 高速船乗船券割引クーポン 1,000円分'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '1.広島港宇品旅客ターミナル1F　①or②番窓口',
                '住所：広島市南区宇品海岸一丁目13-26',
                '2.グランドプリンスホテル広島1Fトラベルデスク',
                '住所：広島市南区元宇品町23-1',
                '3.宮島港第3桟橋きっぷ売り場',
                '住所：廿日市市宮島町胡町地先'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://setonaikaikisen.co.jp/kouro/highspeedship/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['利用可能路線: ','<br>・広島港→宮島港<br>・プリンスホテル前→宮島港<br>・宮島港→広島港<br>・宮島港→プリンスホテル前','※いずれかの区間　1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['窓口でQRコードを認証せずに直接船に来られた場合は乗れませんのでご注意ください。'] },
                { cid: 3, text: ['時刻表はHPをご覧ください。'] },
                { cid: 4, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 5, text: ['満員になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfhiroshima/jp/Orizuru_JP.png'),
          title: ['おりづるタワー入場券割引クーポン 1,000円分'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒730-0051 広島県広島市中区大手町一丁目2番1号 (1Fインフォメーションカウンター)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.orizurutower.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['イベント等により臨時休館する場合があります。詳細はご来場前にHPでご確認ください。'] },
                { cid: 3, text: ['(再入場について)','<br>・ご来場当日に限り、何度でも再入場できます。<br>・再入場ご希望の場合は、ゲート出口にてスタッフにお申し出下さい。手にスタンプを押させていただいております。再入場には、入場券とスタンプの確認が必要となります。'] },
                { cid: 4, text: [
                  '(展望台のご利用にあたって)',
                  '<br>・館内での事故防止のため、キャリーバックや大きな荷物等、当館がふさわしくないと判断した物のお持込はお断りする場合があります。コインロッカー（1F入場ゲート横／有料）がございますのでご利用ください。',
                  '<br>・館外でお買い上げの飲食物のお持込はできません。あらかじめご了承ください。',
                  '<br>・三脚を使用しての撮影は他のお客様のご迷惑となりますので固くお断りしております。'
                ] },
                { cid: 5, text: ['天候により景色を十分にご覧いただけない場合、また強風などにより閉鎖させていただく場合もございます。あらかじめご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfhiroshima/jp/Peacecle_JP.png'),
          title: ['広島市シェアサイクル「ぴーすくる」1日券【広島市内】'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒732-0828　広島市南区京橋町2-29　SSビル1F (ぴーすくる運営事務所)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://docomo-cycle.jp/hiroshima/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['他の1日券販売窓口ではQRコードの認証を受け付けておりません。'] },
                { cid: 2, text: ['同日23:59時まで有効。'] },
                { cid: 3, text: ['自転車は身長145㎝以上～に方に限ります。'] },
                { cid: 4, text: ['市内140箇所ある専用サイクルポートに返却してください。'] },
                { cid: 5, text: ['一部のサイクルポートでのご利用／返却時間に制限があります。詳しくはサイトをご確認ください。'] },
                { cid: 6, text: ['自転車に乗る際にヘルメット着用が努力義務化されております。ヘルメットご希望の方は有料でお貸しします。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfhiroshima/jp/ekie_JP.png'),
          title: ['JR広島駅前のショッピングセンター「ekie」買い物商品券　1,500円分'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒732-0822　広島市南区松原町1-2 (2階インフォメーションカウンター)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.ekie.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['会計時に各店舗で商品券を提示してください。（QRコードの提示ではご利用いただけません。必ずクーポン券に引き換えてから各店舗をご利用ください）'] },
                { cid: 2, text: ['本券は現金とのお引換え及びおつりのお渡しはできません。'] },
                { cid: 3, text: ['臨時休業・満席等の事情でご利用いただけなかった場合も払戻はありません。'] },
                { cid: 4, text: ['商品券対象外店舗: 日本旅行、セブン-イレブンハートイン、伊達眼科医院、もみじ薬局、広島駅ビル 歌野原歯科、広島駅 内科・こどもクリニック、河合塾マナビス'] },
                { cid: 5, text: ['クーポン券(実券)は七日間有効です。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfhiroshima/jp/Sozo_JP.png'),
          title: ['「ひろしまお好み物語 駅前ひろば」で使える1,500円分商品券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒732-0822 広島県広島市南区松原町１０−１ 広島フルフォーカスビル 6F「お好み焼き そぞ」'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://ekimae-hiroba.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['お会計時に各店舗で商品券を提示してください。（QRコードの提示ではご利用いただけません。必ず商品券に引き換えてから各店舗をご利用ください）'] },
                { cid: 2, text: ['現金とお取り替えいたしません。'] },
                { cid: 3, text: ['盗難紛失又は減失等に対しては当社はその責を負いません。'] },
                { cid: 4, text: ['臨時休業・満席等の事情でご利用いただけなかった場合も払戻はありません。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfhiroshima/jp/Bic_Camera_JP.png'),
          title: ['JR広島駅前の家電量販店「ビックカメラ広島駅前店」商品券　1,000円分'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒732-0822　広島県広島市南区松原町5-1　1階総合案内カウンター'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.biccamera.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['お会計時に各店舗で商品券を提示してください。（QRコードの提示ではご利用いただけません。必ず商品券に引き換えてから各店舗をご利用ください）'] },
                { cid: 2, text: ['現金とお取り替えいたしません。'] },
                { cid: 3, text: ['盗難紛失又は減失等に対しては当社はその責を負いません。'] },
                { cid: 4, text: ['商品券、はがき、印紙、タバコ、その他当社が指定した商品等との引き換えはいたしません。'] },
                { cid: 5, text: ['臨時休業・満席等の事情でご利用いただけなかった場合も払戻はありません。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfhiroshima/jp/LobbyLounge_JP.jpg'),
          title: ['ホテルグランヴィア広島 メザニンフロア「ロビーラウンジ」 ケーキセット'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒732-0822 広島県広島市南区松原町1-5 メザニンフロア「ロビーラウンジ」(ロビー中2階)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.hgh.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['含む内容: パティシエ特製の数種類のケーキと、お好みのコーヒーや紅茶'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。','<br>※臨時休業・満席等の事情でご利用いただけなかった場合があります。'] },
                { cid: 3, text: ['商品内容の写真はイメージです'] },
                { cid: 4, text: ['追加分の利用はお客様負担になります。'] },
                { cid: 5, text: ['テイクアウト不可。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfhiroshima/jp/dahe_JP.jpg'),
          title: ['大和ミュージアム入場券+ミュージアムショップ1000円分'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒737-0029　広島県呉市宝町5番20号 (入場口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://yamato-museum.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['入館時に館内利用券1000円を受け取り下さい。'] },
                { cid: 2, text: ['1000円利用券はミュージアムショップでご利用可能です。おつりは出ません。'] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                ]
            }
          ]
        },
      ]
    };
  },
  created () {
    // this.$router.push('/hfPage?id=HFHiroshima&l=jp')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-hiroshima-1-week-free-pass/jp', '_self')
  }
};
</script>
  <style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #fffba0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1a1311;
    font-weight: 700;
    margin-top: 0;
    .title {
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span {
        color: #fa6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }

    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color: #ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>